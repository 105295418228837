import aboutUsApi from 'api/aboutUsApi';
import productApi from 'api/ecoSystemApi';
import { selectLanguage } from 'app/adminSlice';
import { SplitButton } from 'components/Buttons';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MedialProductList, ProductList } from '../components';

const MedicalProductPage = () => {
	const language = useSelector(selectLanguage);
	const [load, setLoad] = useState(false);
	// staff list
	const [medicalProductList, setMedicalProductList] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await aboutUsApi.getListProduct();

				if (res.succeeded) {
					setMedicalProductList(language === 'vn' ? res.data.vn : res.data.en);
				}
			} catch (error) {}
		};

		fetchData();
		setLoad(false)
	}, [language,load]);

	// return
	return (
		<Card className="shadow">
			<Card.Header className="fw-bold">
				{language === 'vn' ? 'Sản phẩm y tế' : 'Medical Product'}
			</Card.Header>
			<Card.Body>
			<Link to={`${process.env.REACT_APP_PUBLIC_URL}/medical-product/add`}>
			<div className="mb-3">
					<SplitButton
						icon="fas fa-add"
						text={language === 'vn' ? 'Thêm mới' : 'Add'}
					/>
				</div>
			</Link>
				<MedialProductList
					language={language}
					medicalProductList={medicalProductList}
					setLoad={setLoad}
				/>
			</Card.Body>
		</Card>
	);
};

export default MedicalProductPage;
