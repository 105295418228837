import { useState } from "react";
//bootstrap
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
//component
import staffApi from "api/staffApi";
import { Confirm } from "components/Common/Confirm";
import ReactQuillComponent from "components/Common/ReactQuillComponent";
import { InputField } from "components/FormFields";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
//form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useTrans from "../../../hook/useTrans";

const StaffFormUpdate = (props) => {
    const t = useTrans();
    const { language, paramSubmit, label_btn, pageInfo, idStaff } = props;

    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(pageInfo.en.image);
    const [previewImageHover, setPreviewImageHover] = useState(
        pageInfo.en.imageHover
    );

    const handleClose = () => {
        setShowModal(false);
    };
    const handleShow = () => {
        setShowModal(true);
    };
    const handleCancel = () => {
        navigate(-1);
    };

    // function toDataURL(url, callback) {
    //     var xhr = new XMLHttpRequest();
    //     xhr.onload = function () {
    //         var reader = new FileReader();
    //         reader.onloadend = function () {
    //             callback(reader.result);
    //         };
    //         reader.readAsDataURL(xhr.response);
    //     };
    //     xhr.open("GET", url);
    //     xhr.responseType = "blob";
    //     xhr.send();
    // }

    const [showVN, setShowVN] = useState(true);
    const [showEN, setShowEN] = useState(true);

    const [baseImage, setBaseImage] = useState(null);
    const [baseImageHover, setBaseImageHover] = useState(null);

    const [expEditorVN, setExpEditorVN] = useState(pageInfo.vn.experienced);
    const [expEditorEN, setExpEditorEN] = useState(pageInfo.en.experienced);

    const uploadImage = async (e, type) => {
        const file = e.target.files[0];

        if (!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
            if (type === "image") {
                setError("image", {
                    message:
                        "Chỉ chấp nhận các định dạng jpg, jpeg, png, gif, webp.",
                });
            } else {
                setError("image_hover", {
                    message:
                        "Chỉ chấp nhận các định dạng jpg, jpeg, png, gif, webp.",
                });
            }
            return;
        } else {
            const base64 = await convertBase64(file);

            if (type === "image") {
                setBaseImage(base64);
                setPreviewImage(URL.createObjectURL(file));
                clearErrors("image");
            } else {
                setBaseImageHover(base64);
                setPreviewImageHover(URL.createObjectURL(file));
                clearErrors("image_hover");
            }
        }
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const onSubmit = async (data) => {
        // if (baseImage.trim() && nameImage.trim()) {
        const {
            name,
            order,
            role_en,
            role_vn,
            position_en,
            position_vn,
            // experienced_en,
            // experienced_vn,
            // image,
            // image_hover,
        } = data;

        const data_post = {
            name: name,
            en: {
                role: role_en,
                position: position_en,
                experienced: expEditorEN,
                // experienced: experienced_en,
            },
            vn: {
                role: role_vn,
                position: position_vn,
                experienced: expEditorVN,
            },
            images: null,
            // image: baseImage,
            // imageHover: baseImageHover,

            orderNumber: order,
        };

        if (baseImage && !baseImageHover) {
            data_post.images = {
                image: baseImage,
            };
        } else if (!baseImage && baseImageHover) {
            data_post.images = {
                imageHover: baseImageHover,
            };
        } else if (baseImage && baseImageHover) {
            data_post.images = {
                image: baseImage,
                imageHover: baseImageHover,
            };
        }

        const res = await staffApi.update({ ...data_post, id: idStaff });

        if (res.succeeded) {
            toast.success("Update staff success");
            navigate(`${process.env.REACT_APP_PUBLIC_URL}/staff`);
        }
    };

    const schema = yup
        .object({
            name: yup
                .string()
                .required()
                .max(30, "Vượt quá số kí tự cho phép, tối đa chỉ 30 kí tự"),
            role_en: yup
                .string()
                .required()
                .max(30, "Vượt quá số kí tự cho phép, tối đa chỉ 30 kí tự"),
            role_vn: yup
                .string()
                .required()
                .max(30, "Vượt quá số kí tự cho phép, tối đa chỉ 30 kí tự"),
            position_en: yup
                .string()
                .max(150, "Vượt quá số kí tự cho phép, tối đa chỉ 150 kí tự"),
            position_vn: yup
                .string()
                .max(150, "Vượt quá số kí tự cho phép, tối đa chỉ 150 kí tự"),
            experienced_en: yup
                .string()
                .required()
                .max(300, "Vượt quá số kí tự cho phép, tối đa chỉ 300 kí tự"),
            experienced_vn: yup
                .string()
                .required()
                .max(300, "Vượt quá số kí tự cho phép, tối đa chỉ 300 kí tự"),
            order: yup
                .number()
                .required()
                .typeError("Bạn phải nhập số")
                .moreThan(0, "Số phải lớn hơn 0"),
        })
        .required();
    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: pageInfo.vn.name,
            order: pageInfo.en.orderNumber,
            role_en: pageInfo.en.role,
            role_vn: pageInfo.vn.role,
            position_en: pageInfo.en.position,
            position_vn: pageInfo.vn.position,
            experienced_en: pageInfo.en.experienced || "...",
            experienced_vn: pageInfo.vn.experienced || "...",
            //   image: null,
            //   image_hover: null,
        },
        resolver: yupResolver(schema),
    });

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} className="">
                <InputField
                    control={control}
                    name="name"
                    label={<>{t.staff.name}</>}
                    placeholder="name staff"
                    className="w-50"
                />
                <InputField
                    control={control}
                    name="order"
                    label={<>{t.staff.orderNumber}</>}
                    placeholder="order"
                    className="w-50"
                />
                <Row>
                    <Col>
                        <Button
                            variant="info"
                            className="mb-2 d-block"
                            onClick={() => setShowVN(!showVN)}
                        >
                            Vietnam
                        </Button>{" "}
                        {showVN && (
                            <>
                                <InputField
                                    control={control}
                                    name="role_vn"
                                    label={<>{t.staff.role}</>}
                                    placeholder="role"
                                />
                                <InputField
                                    control={control}
                                    name="position_vn"
                                    label={<>{t.staff.position}</>}
                                    placeholder="position"
                                    as="textarea"
                                    style={{ height: "100px" }}
                                />{" "}
                                {/* <InputField
                                    control={control}
                                    name="experienced_vn"
                                    label={<>Experienced VN</>}
                                    placeholder="experienced"
                                    as="textarea"
                                    style={{ height: "70px" }}
                                    // style={{ height: "70px" }}
                                /> */}
                                <ReactQuillComponent
                                    label={<>{t.staff.experienced}</>}
                                    setDataEditor={setExpEditorVN}
                                    dataEditor={expEditorVN}
                                    name="experienced_vn"
                                    defaultValue={expEditorVN}
                                    // defaultValue={
                                    //          pageInfo.vn.experienced
                                    // }
                                />
                            </>
                        )}
                    </Col>
                    <Col>
                        <Button
                            variant="info"
                            className="mb-2 d-block"
                            onClick={() => setShowEN(!showEN)}
                        >
                            English
                        </Button>{" "}
                        {showEN && (
                            <>
                                <InputField
                                    control={control}
                                    name="role_en"
                                    label={<>{t.staff.role}</>}
                                    placeholder="role"
                                />
                                <InputField
                                    control={control}
                                    name="position_en"
                                    label={<>{t.staff.position}</>}
                                    placeholder="position"
                                    as="textarea"
                                    style={{ height: "100px" }}
                                />
                                <ReactQuillComponent
                                    label={<>{t.staff.experienced}</>}
                                    setDataEditor={setExpEditorEN}
                                    dataEditor={expEditorEN}
                                    name="experienced_en"
                                    defaultValue={expEditorEN}
                                />
                            </>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex flex-column">
                        <InputField
                            control={control}
                            name="image"
                            label={<>{t.staff.image}</>}
                            placeholder="image"
                            type="file"
                            onChange={(e) => uploadImage(e, "image")}
                        />
                        <img
                            className="image_preview"
                            src={previewImage}
                            alt="preview-image"
                        />
                    </Col>
                    <Col className="d-flex flex-column">
                        <InputField
                            control={control}
                            name="image_hover"
                            label={<>{t.staff.image}</>}
                            placeholder="image hover"
                            type="file"
                            onChange={(e) => uploadImage(e, "image_hover")}
                        />
                        <img
                            className="image_preview"
                            src={previewImageHover}
                            alt="preview-image-hover"
                        />
                    </Col>
                </Row>

                <Button type="submit" className="mt-3">
                    <i className="fa-solid fa-check"></i>
                    &nbsp;&nbsp;{t.button.update}
                </Button>
                <Button
                    className="mt-3 ms-3"
                    variant="secondary"
                    onClick={() => handleShow()}
                >
                    <i className="fa fa-times"></i>
                    <span>&nbsp;&nbsp;{t.button.cancel}</span>
                </Button>
            </Form>
            <Confirm
                show={showModal}
                message="Bạn có muốn hủy không ?"
                onConfirm={handleCancel}
                onClose={handleClose}
                textCancel="Không"
            />
        </>
    );
};

export default StaffFormUpdate;
