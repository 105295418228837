const { default: axiosClient } = require("./axiosClient");
const apiUrl = "/AboutUs";

const aboutUsApi = {
    getListTeam: () => {
        const url = `${apiUrl}/get-list-team`;
        return axiosClient.get(url);
    },
    getDetailStaff: (id) => {
        const url = `${apiUrl}/get-detail-staff/${id}`;
        return axiosClient.get(url);
    },
    getListProduct: () => {
        const url = `${apiUrl}/get-list-product`;
        return axiosClient.get(url);
    },
    getDetailProduct: (id) => {
        const url = `${apiUrl}/get-detail-product/${id}`;
        return axiosClient.get(url);
    },
};

export default aboutUsApi;
