const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
    loading: true,
    currentUser: JSON.parse(sessionStorage.getItem("currentUser")) || null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
            sessionStorage.setItem(
                "currentUser",
                JSON.stringify(action.payload)
            );

            return state;
        },
    },
});

// Selections
export const selectAuthLoading = (state) => state.auth.loading;
export const selectCurrentUser = (state) => state.auth.currentUser;

// Actions
export const authActions = authSlice.actions;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
