import seoApi from "api/seoApi";
import { selectLanguage } from "app/adminSlice";
import { SplitButton } from "components/Buttons";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SEOList } from "../components";

const SEOPage = () => {
    const language = useSelector(selectLanguage);

    const [load, setLoad] = useState(false);
    const [seoList, setSEOList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await seoApi.getList();

                if (res.succeeded) {
                    setSEOList(language === "vn" ? res.data.vn : res.data.en);
                    // setLoad(false);
                }
            } catch (error) {}
        };

        fetchData();
        setLoad(false);
        // if (load) fetchData();
    }, [language, load]);

    return (
        <Card className="shadow">
            <Card.Header className="fw-bold">SEO</Card.Header>
            <Card.Body>
                <div className="mb-3">
                    <Link to={`${process.env.REACT_APP_PUBLIC_URL}/seo/add`}>
                        <SplitButton
                            icon="fas fa-add"
                            text={language === "vn" ? "Thêm mới" : "Add"}
                        />
                    </Link>
                </div>

                <SEOList
                    language={language}
                    seoList={seoList}
                    setLoad={setLoad}
                />
            </Card.Body>
        </Card>
    );
};

export default SEOPage;
