import axiosClient from "./axiosClient";
const apiUrl = "/EcoSystem";

const ecoSystemApi = {
    // GET
    getList: () => {
        const url = `${apiUrl}/get-list`;
        return axiosClient.get(url);
    },
    getDetail: (id) => {
        const url = `${apiUrl}/get-detail/${id}`;
        return axiosClient.get(url);
    },

    // POST
    create: (params) => {
        const url = `${apiUrl}/create`;
        return axiosClient.post(url, params);
    },
    update: (params) => {
        const url = `${apiUrl}/update`;
        return axiosClient.post(url, params);
    },

    // DELETE
    delete: (params) => {
        const url = `${apiUrl}/delete`;
        return axiosClient.put(url, params);
    },
};

export default ecoSystemApi;
