import { selectLanguage } from "app/adminSlice";
import { useSelector } from "react-redux";
import en from "../lang/en";
import vn from "../lang/vn";
const useTrans = () => {
    const language = useSelector(selectLanguage);
    const trans = language === "vn" ? vn : en;
    return trans;
};
export default useTrans;
