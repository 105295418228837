import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
//bootstrap
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
//component
import ecoSystemApi from "api/ecoSystemApi";
import { Confirm } from "components/Common/Confirm";
import ReactQuillComponent from "components/Common/ReactQuillComponent";
import { InputField } from "components/FormFields";
//form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useTrans from "../../../hook/useTrans";
const EcoSystemForm = (props) => {
    const t = useTrans();
    const { language, paramSubmit, label_btn, method, pageInfo } = props;

    const [dataDesEN, setDataDesEN] = useState(pageInfo.en.description);
    const [dataDesVN, setDataDesVN] = useState(pageInfo.vn.description);
    const [showModal, setShowModal] = useState(false);

    const id_eco = useParams().id;

    const navigate = useNavigate();

    //confirm cancel
    const handleClose = () => {
        setShowModal(false);
    };
    const handleShow = () => {
        setShowModal(true);
    };
    const handleCancel = () => {
        navigate(-1);
    };

    const onSubmit = async (data) => {
        const { title, title_en, des, des_en } = data;

        const data_post = {
            vn: {
                title: title,
                description: des,
            },
            en: {
                title: title_en,
                description: des_en,
            },
        };
        const data_update = {
            id: id_eco,
            vn: {
                title: title,
                description: dataDesVN,
            },
            en: {
                title: title_en,
                description: dataDesEN,
            },
        };
        const data_compare = {
            vn: {
                id: id_eco,
                title: title,
                description: dataDesVN,
            },
            en: {
                id: id_eco,
                title: title_en,
                description: dataDesEN,
            },
        };

        const res =
            method === "add"
                ? await ecoSystemApi.create(data_post)
                : await ecoSystemApi.update(data_update);
        if (method === "add") {
            if (res.succeeded) {
                toast.success("Add ecoSystem success");
                navigate(`${process.env.REACT_APP_PUBLIC_URL}/ecoSystem`);
            }
        } else {
            if (JSON.stringify(data_compare) === JSON.stringify(pageInfo)) {
                toast.error("Nothing changes");
                navigate(`${process.env.REACT_APP_PUBLIC_URL}/ecoSystem`);
            } else if (res.succeeded) {
                toast.success("Update ecoSystem success");
                navigate(`${process.env.REACT_APP_PUBLIC_URL}/ecoSystem`);
            }
        }
    };
    // if(pageInfo){
    //     let des_html =  dangerouslySetInnerHTML={{
    //         __html: item.description,
    //     }}
    // }

    const schema = yup
        .object({
            title: yup.string().required(),
            title_en: yup.string().required(),
            des: yup.string().required(),
            des_en: yup.string().required(),
        })
        .required();
    const {
        control,
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: pageInfo
            ? {
                  title: pageInfo.vn.title,
                  title_en: pageInfo.en.title,
                  des: pageInfo.vn.description,
                  des_en: pageInfo.en.description,
              }
            : {
                  title: "test add title",
                  title_en: "test add title EN",
                  des: "tit eco",
                  des_en: "tit EN eco",
              },
        resolver: yupResolver(schema),
    });

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col>
                        <InputField
                            control={control}
                            name="title"
                            label={t.ecoSystem.title}
                            placeholder="title"
                        />
                        {/* <InputField
                            control={control}
                            name="des"
                            label={<>Description VN</>}
                            placeholder="des"
                            as="textarea"
                            style={{ height: "70px" }}
                        /> */}
                        <ReactQuillComponent
                            label={t.ecoSystem.description}
                            setDataEditor={setDataDesVN}
                            dataEditor={dataDesVN}
                            name="experienced_vn"
                            defaultValue={pageInfo.vn.description}
                        />
                    </Col>
                    <Col>
                        <InputField
                            control={control}
                            name="title_en"
                            label={t.ecoSystem.title}
                            placeholder="title_en"
                        />
                        <ReactQuillComponent
                            label={t.ecoSystem.description}
                            setDataEditor={setDataDesEN}
                            dataEditor={dataDesEN}
                            name="experienced_en"
                            defaultValue={pageInfo.en.description}
                        />
                    </Col>
                </Row>

                <Button type="submit" className="mt-3">
                    <i className="fa-solid fa-check"></i>
                    &nbsp;&nbsp;{t.button.update}
                </Button>
                <Button
                    className="mt-3 ms-3"
                    variant="secondary"
                    onClick={() => handleShow()}
                >
                    <i className="fa fa-times"></i>
                    <span>&nbsp;&nbsp;{t.button.cancel}</span>
                </Button>
            </Form>
            <Confirm
                show={showModal}
                message="Bạn có muốn hủy không ?"
                onConfirm={handleCancel}
                onClose={handleClose}
                textCancel="Không"
            />
        </>
    );
};

export default EcoSystemForm;
