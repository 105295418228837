import axios from "axios";
import axiosClient from "./axiosClient";
const apiUrl = `${process.env.REACT_APP_API_URL}/Account`;

const authApi = {
    login: (params) => {
        const url = `${apiUrl}/authenticate`;
        return axiosClient.post(url, params);
    },
};

export default authApi;
