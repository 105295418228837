import { CircleButton } from "components/Buttons";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import medicalProductApi from "api/medicalProductApi";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import useTrans from "../../../hook/useTrans";
import { Confirm } from "components/Common/Confirm";

export const MedialProductList = (props) => {
    const { language, medicalProductList, setLoad } = props;

    const [show, setShow] = useState(false);
    const [deleteId, setdeleteId] = useState("");
    const [medicalDeleteName, setMedicalDeleteName] = useState("");
    const navigate = useNavigate();
    const t = useTrans();

    const header = [
        {
            name: "title",
            title: {
                vn: "Tên",
                en: "Title",
            },
        },
        {
            name: "description",
            title: {
                vn: "Mô tả",
                en: "Description",
            },
        },
    ];

    const handleShow = (id, title) => {
        setShow(true);
        setdeleteId(id);
        setMedicalDeleteName(title);
    };

    const handleClose = () => {
        setShow(false);
        setdeleteId("");
    };

    const handleDelete = async () => {
        setShow(false);
        const param = { id: deleteId, language: "ev" };
        const res = await medicalProductApi.delete(param);
        if (res.succeeded === true) {
            toast.success(t.popupDelete.message);
            setLoad(true);
        }
    };

    return medicalProductList.length > 0 ? (
        <div className="table-reponsive">
            <Table className="mb-0 table-hover">
                <thead className="table-primary thead-sticky">
                    <tr align="center">
                        {header.map((item) => (
                            <th key={item.name}>
                                {language === "vn"
                                    ? item.title.vn
                                    : item.title.en}
                            </th>
                        ))}
                        <th>{language === "vn" ? "Công cụ" : "Controls"}</th>
                    </tr>
                </thead>
                <tbody>
                    {medicalProductList.map((item) => (
                        <tr key={item.id.toString()}>
                            <td>
                                <div className="item">
                                    <img
                                        src={item.image}
                                        alt={item.title}
                                        width={100}
                                    />
                                    {item.title}
                                </div>
                            </td>
                            <td>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.smallDescription,
                                    }}
                                />
                            </td>

                            <td>
                                <div className="controls">
                                    {/* <CircleButton
                                    icon="fas fa-eye"
                                    title={
                                        language === "vn"
                                            ? "Xem chi tiết"
                                            : "Detail"
                                    }
                                    size="md"
                                /> */}
                                    <CircleButton
                                        icon="fas fa-edit"
                                        title={
                                            language === "vn"
                                                ? "Cập nhật"
                                                : "Edit"
                                        }
                                        size="md"
                                        onClick={() =>
                                            navigate(
                                                `${process.env.REACT_APP_PUBLIC_URL}/medical-product/update/` +
                                                    item.id
                                            )
                                        }
                                    />
                                    <CircleButton
                                        variant="danger"
                                        icon="fas fa-trash"
                                        title={
                                            language === "vn" ? "Xóa" : "Delete"
                                        }
                                        size="md"
                                        onClick={() =>
                                            handleShow(item.id, item.title)
                                        }
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Confirm
                show={show}
                message={`${t.popupDelete.title} " ${medicalDeleteName} " ?`}
                onConfirm={handleDelete}
                onClose={handleClose}
            />
        </div>
    ) : (
        <div className="alert alert-danger mb-0">Không có dữ liệu</div>
    );
};
