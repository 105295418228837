export default {
    popupDelete: {
        header: "Xóa Thông Tin",
        title: "Bạn có muốn xóa ",
        yes: "Đồng ý",
        no: "Hủy",
        message: "Xóa sản phẩm thành công.",
    },
    popup: {
        yes: "Đồng ý",
        no: "Hủy",
        message: "Bạn có muốn hủy không ?",
        logOut: "Bạn có muốn đăng xuất ?",
    },
    button: {
        add: "Thêm mới",
        update: "Cập nhật",
        cancel: "Thoát",
    },
    staff: {
        staffAdd: "Thêm nhân viên mới ",
        name: "Tên Nhân viên",
        orderNumber: "Số thứ tự",
        role: "Vai trò",
        position: "Chức Vụ",
        experienced: "Kinh Nghiệm ",
        image: "Hình ảnh",
        imageHover: "Ảnh khi di chuột vào",
        staffDetail: "Cập nhật thông tin nhân viên.",
    },
    ecoSystem: {
        title: "Hệ sinh thái ",
        subTitle: "Tiêu đề",
        description: "Mô tả",
        ecoSystemDetail: "Cập nhật hệ sinh thái.",
    },
    medicalProduct: {
        medicalProductAdd: "Thêm Sản phẩm y tế mới",
        image: "Hình ảnh",
        title: "Tên sản phẩm",
        subTitle: "Ứng dụng",
        smallDescription: "Chi tiết nổi bật",
        description: "Chi tiết",
        MedicalProductUpdate: "Cập nhật lại sản phẩm y tế.",
        bgColor: "Màu nền",
    },
    news: {
        newsAdd: "Thêm Tin tức mới.",
        newUpdate: "Cập nhật lại tin tức.",
        image: "Hình ảnh",
        title: "Tiêu đề",
        keyword: "Từ khóa",
        smallDescription: "Thông tin nổi bật",
        description: "Chi tiết",
    },
    seo: {
        addSeo: "Thêm SEO mới",
        updateSeo: "Cập nhật SEO ",
        page: "Tên trang",
        title: "Tiêu đề",
        description: "Mô tả",
        keyword: "Từ khóa",
    },
    confirm: {
        no: "Không",
    },
    header: {
        profile: "Hồ sơ",
        setting: "Cài đặt",
        logout: "Đăng xuất",
    },
};
