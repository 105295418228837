import { selectLanguage } from "app/adminSlice";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const MenuItem = (props) => {
    const { href, icon, title } = props;
    const active = window.location.pathname.includes(href);
    const language = useSelector(selectLanguage);

    return (
        <Nav.Item as="li" className={active ? "active" : ""}>
            <Link className="nav-link" to={href}>
                <i className={icon}></i>
                <span>{language === "vn" ? title.vn : title.en}</span>
            </Link>
        </Nav.Item>
    );
};
