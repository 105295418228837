import React from "react";

const HomePage = () => {
    return (
        <div className="d-flex flex-column">
            <span>HomePage</span>
            <img
                src="https://img.freepik.com/free-vector/coming-soon-background-with-spot-light-design_1017-25515.jpg?w=2000"
                alt="coming-soon"
                width="900"
            />
        </div>
    );
};

export default HomePage;
