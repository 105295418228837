export default {
    popupDelete: {
        header: "Delete",
        title: "Do you want to delete ",
        yes: "Yes",
        no: "No",
        message: "Remove new successfully.",
    },
    popup: {
        yes: "Yes",
        no: "No",
        message: "Do you want to cancel ?",
        logOut: "Do you want to log out ?",
    },
    button: {
        add: "Add",
        update: "Update",
        cancel: "Cancel",
    },
    staff: {
        staffAdd: "Add new Staff",
        name: "Name",
        orderNumber: "Order number",
        role: "Role",
        position: "Position",
        experienced: "Experienced ",
        image: "Image",
        imageHover: "Image hover",
        staffDetail: "staff Detail",
    },
    ecoSystem: {
        title: "EcoSystem",
        subTitle: "Title",
        description: "Description",
        ecoSystemDetail: "ecoSystem Detail",
    },
    medicalProduct: {
        medicalProductAdd: "Medical Product Add",
        image: "Image",
        title: "Title",
        subTitle: "SubTitle",
        smallDescription: "SmallDescription",
        description: "Description",
        MedicalProductUpdate: "Medical Product Detail",
        bgColor: "Backgroud Color",
    },
    news: {
        newsAdd: "News Add",
        newUpdate: "News Detail",
        image: "Image",
        title: "Title",
        keyword: "Keyword",
        smallDescription: "SmallDescription",
        description: "Description",
    },
    seo: {
        addSeo: "Add new SEO",
        updateSeo: "Seo Detail",
        page: "Page",
        title: "Title",
        description: "Description",
        keyword: "Keyword",
    },
    confirm: {
        no: "No",
    },
    header: {
        profile: "Profile",
        setting: "Settings",
        logout: "Log out",
    },
};
