import { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { InputField } from "components/FormFields";
import newsApi from "api/newsApi";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { Confirm } from "components/Common/Confirm";
import ReactQuillComponent from "components/Common/ReactQuillComponent";
import useTrans from "../../../hook/useTrans";

const NewsAdd = (props) => {
    const navigate = useNavigate();
    const id = useParams().id;
    const t = useTrans();
    const { label_btn, pageInfo } = props;
    const [showVN, setShowVN] = useState(true);
    const [showEN, setShowEN] = useState(true);
    //editor
    const [dataVn, setDataVn] = useState(pageInfo.vn.description);
    const [dataEn, setDataEn] = useState(pageInfo.en.description);

    const onSubmit = async (data) => {
        let {
            title_en,
            title_vn,
            date,
            smallDescription_en,
            smallDescription_vn,
            keyword_en,
            keyword_vn,
        } = data;
        const data_post = {
            id: id,
            imageCover: imageCover,
            date: date,
            en: {
                title: title_en,
                smallDescription: smallDescription_en,
                description: dataEn,
                keyword: keyword_en,
            },
            vn: {
                title: title_vn,
                smallDescription: smallDescription_vn,
                description: dataVn,
                keyword: keyword_vn,
            },
        };

        const res = await newsApi.update(data_post);
        if (res.succeeded) {
            toast.success("Update New success");
            navigate(`${process.env.REACT_APP_PUBLIC_URL}/news`);
        }
    };
    const schema = yup
        .object({
            title_en: yup.string().required(),
            title_vn: yup.string().required(),
            smallDescription_en: yup.string().required(),
            smallDescription_vn: yup.string().required(),
            description_en: yup.string().required(),
            description_vn: yup.string().required(),
            keyword_en: yup.string().required(),
            keyword_vn: yup.string().required(),
        })
        .required();
    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: pageInfo
            ? {
                  title_vn: pageInfo.vn.title,
                  title_en: pageInfo.en.title,
                  date: pageInfo.date,
                  smallDescription_en: pageInfo.en.smallDescription,
                  smallDescription_vn: pageInfo.vn.smallDescription,
                  description_en: pageInfo.en.description,
                  description_vn: pageInfo.vn.description,
                  keyword_en: pageInfo.en.keyword,
                  keyword_vn: pageInfo.vn.keyword,
              }
            : {
                  title_vn: "",
                  title_en: "",
                  date: "",
                  smallDescription_vn: "",
                  smallDescription_en: "",
                  description_en: "",
                  description_vn: "",
                  keyword_vn: "",
                  keyword_en: "",
              },
        resolver: yupResolver(schema),
    });
    const [imageCover, setImageCover] = useState();
    const uploadImageCover = async (e,type) => {
        const fileCover = e.target.files[0];
        if(!fileCover.name.match(/\.(jpg|jpeg|png|gif|webp)$/)){
            if(type === 'imageCover' ){
                setError("imageCover", {
                    message:
                        "Chỉ chấp nhận các định dạng jpg, jpeg, png, gif, webp.",
                });
            }return
        }else{
            const base64 = await convertBase64(fileCover);
            setImageCover(base64);
            clearErrors("imageCover");

        }

    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const [show, setShow] = useState(false);
    const message = t.popup.message;
    const onClose = () => {
        setShow(false);
    };
    const onConfirm = () => {
        navigate(-1);
    };
    const hanldeCancel = () => {
        setShow(true);
    };
    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)} className="row">
                <div className="col-6">
                    <InputField
                        label={t.news.image}
                        control={control}
                        type="file"
                        name="imageCover"
                        onChange={(e) => {
                            uploadImageCover(e,'imageCover');
                        }}
                    />
                    <br />
                    <img
                        src={imageCover ? imageCover : pageInfo.en.image}
                        style={{ margin: "10px" }}
                        width="150px"
                    />
                </div>
                <hr style={{ margin: "10px", width: "98%" }} />
                <div className="col-6">
                    <Button
                        variant="secondary"
                        className="mb-2 d-block"
                        onClick={() => setShowVN(!showVN)}
                    >
                        Vietnam
                    </Button>{" "}
                    {showVN && (
                        <>
                            <InputField
                                control={control}
                                label={t.news.title}
                                name="title_vn"
                                placeholder={t.news.title}
                            />
                            <InputField
                                control={control}
                                name="keyword_vn"
                                placeholder={t.news.keyword}
                                label={t.news.keyword}
                            />
                            <InputField
                                control={control}
                                name="smallDescription_vn"
                                placeholder={t.news.smallDescription}
                                label={t.news.smallDescription}
                                as="textarea"
                            />
                            <ReactQuillComponent
                                label={t.news.description}
                                setDataEditor={setDataVn}
                                dataEditor={dataVn}
                                defaultValue={dataVn}
                                modules_new={true}
                                formats_new={true}
                            />
                        </>
                    )}
                </div>

                <div className="col-6">
                    <Button
                        variant="secondary"
                        className="mb-2 d-block"
                        onClick={() => setShowEN(!showEN)}
                    >
                        English
                    </Button>
                    {showEN && (
                        <>
                            <InputField
                                control={control}
                                name="title_en"
                                placeholder={t.news.title}
                                label={t.news.title}
                            />
                            <InputField
                                control={control}
                                name="keyword_en"
                                placeholder={t.news.keyword}
                                label={t.news.keyword}
                            />
                            <InputField
                                control={control}
                                name="smallDescription_en"
                                placeholder={t.news.smallDescription}
                                label={t.news.smallDescription}
                                as="textarea"
                            />
                            <ReactQuillComponent
                                label={t.news.description}
                                setDataEditor={setDataEn}
                                dataEditor={dataEn}
                                defaultValue={dataEn}
                                modules_new={true}
                                formats_new={true}
                            />
                        </>
                    )}
                </div>
                <Button
                    className="col-2"
                    style={{ margin: "10px" }}
                    type="submit"
                >
                    <i className="fa-solid fa-check"></i>
                    &nbsp;&nbsp;{t.button.update}
                </Button>
                <Button
                    className="btn btn-secondary col-2"
                    style={{ margin: "10px" }}
                    onClick={hanldeCancel}
                >
                    <i className="fa-solid fa-close"></i>
                    &nbsp;&nbsp;{t.button.cancel}
                </Button>
                <Confirm
                    show={show}
                    onClose={onClose}
                    onConfirm={onConfirm}
                    message={message}
                />
            </Form>
        </div>
    );
};
export default NewsAdd;
