import { adminActions, selectLanguage } from "app/adminSlice";
import { selectCurrentUser } from "app/authSlice";
import { Confirm } from "components/Common/Confirm";
import useTrans from "hook/useTrans";
import { useState } from "react";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import "scss/_admin.scss";

export const Header = (props) => {
    const { handleShowSidebar } = props;
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Language
    const t = useTrans();
    const language = useSelector(selectLanguage);

    const handleChangeVNLanguage = () =>
        dispatch(adminActions.setLanguage("vn"));
    const handleChangeENLanguage = () =>
        dispatch(adminActions.setLanguage("en"));

    // Logout confirm
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

    const handleShowLogoutConfirm = () => setShowLogoutConfirm(true);
    const handleCloseLogoutConfirm = () => setShowLogoutConfirm(false);

    const handleLogout = () => {
        navigate("/logout");
    };

    return (
        <Navbar bg="white" expand className="mb-4 shadow topbar static-top">
            <button
                id="sidebarToggleTop"
                className="btn btn-link d-md-none rounded-circle me-3"
                onClick={handleShowSidebar}
            >
                <i className="fa fa-bars"></i>
            </button>
            <ul className="navbar-nav ms-auto me-2">
                <li className="navbar-item">
                    <div className="language-btn">
                        <div
                            className={language === "vn" ? "selected" : ""}
                            onClick={handleChangeVNLanguage}
                        >
                            VN
                        </div>
                        <div>|</div>
                        <div
                            className={language === "en" ? "selected" : ""}
                            onClick={handleChangeENLanguage}
                        >
                            EN
                        </div>
                    </div>
                </li>
            </ul>
            <ul className="navbar-nav">
                <div className="topbar-divider d-none d-sm-block"></div>

                <Dropdown as="li" className="nav-item no-arrow" align="end">
                    <Dropdown.Toggle id="menu-profile" as={Nav.Link}>
                        <div className="d-flex flex-column">
                            <span className="me-2 d-none d-lg-inline text-gray-600 small">
                                {currentUser?.fullName}
                            </span>
                            <span className="text-info">
                                {currentUser?.roles[0]}
                            </span>
                        </div>
                        <img
                            className="img-profile rounded-circle"
                            src={
                                currentUser?.avatar ||
                                `${process.env.REACT_APP_PUBLIC_URL}/images/no-avatar.webp`
                            }
                            alt={currentUser?.name}
                        />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="animated--grow-in">
                        <Dropdown.Item as={NavLink} to="/profile">
                            <i className="fas fa-fw fa-sm fa-user text-gray-400 me-2"></i>
                            <span>{t.header.profile}</span>
                        </Dropdown.Item>
                        <Dropdown.Item as={NavLink} to="/settings">
                            <i className="fas fa-fw fa-sm fa-cog text-gray-400 me-2"></i>
                            <span>{t.header.setting}</span>
                        </Dropdown.Item>
                        <Dropdown.Divider />

                        <Dropdown.Item onClick={handleShowLogoutConfirm}>
                            <i className="fas fa-fw fa-sm fa-sign-out-alt text-gray-400 me-2"></i>
                            <span>{t.header.logout}</span>
                        </Dropdown.Item>
                        <Confirm
                            show={showLogoutConfirm}
                            message={t.popup.logOut}
                            onConfirm={handleLogout}
                            onClose={handleCloseLogoutConfirm}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </ul>
        </Navbar>
    );
};
