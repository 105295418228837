import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import authApi from "api/authApi";
import { authActions, selectCurrentUser } from "app/authSlice";
import { InputField } from "components/FormFields";
import { Button, FormCheck } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

const LoginPage = (props) => {
    const { isLogin } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(selectCurrentUser);

    const [passwordShown, setPasswordShown] = useState(false);

    useEffect(() => {
        if (isLogin) {
            if (
                currentUser &&
                currentUser.roles.findIndex((item) => item === "SuperAdmin") >=
                    0
            )
                navigate(`${process.env.REACT_APP_PUBLIC_URL}`);
        } else {
            sessionStorage.removeItem("accessToken");
            dispatch(authActions.setCurrentUser(null));

            navigate(`${process.env.REACT_APP_PUBLIC_URL}/login`);
        }
    }, [navigate, dispatch, isLogin]);
    // }, [navigate, dispatch, currentUser, isLogin]);

    // Form
    const validationSchema = yup.object().shape({
        email: yup.string().required("Vui lòng nhập tài khoản!"),
        password: yup.string().required("Vui lòng nhập mật khẩu!"),
    });

    const { control, register, handleSubmit } = useForm({
        defaultValues: {
            email: localStorage.getItem("id_mcare") || "",
            password: "",
            // password: localStorage.getItem("pw_mcare") || "",
            rememberMe: true,
        },
        resolver: yupResolver(validationSchema),
    });

    const handleLogin = async (formValues) => {
        try {
            const { email, password, rememberMe } = formValues;
            const res = await authApi.login({
                email,
                password,
            });

            if (res.succeeded) {
                const { data } = res;

                dispatch(authActions.setCurrentUser(data));
                sessionStorage.setItem(
                    "accessToken",
                    JSON.stringify(data.jwToken)
                );
                if (rememberMe) {
                    localStorage.setItem("id_mcare", email);
                    // localStorage.setItem("pw_mcare", password);
                }

                toast.info(`Wellcome ${data.fullName}`);
                navigate(`${process.env.REACT_APP_PUBLIC_URL}/home`);
            } else {
                toast.error("Sai tên tài khoản hoặc mật khẩu");
            }
        } catch (error) {
            toast.error("Sai tên tài khoản hoặc mật khẩu");
        }
    };

    return (
        <div
            className="bg-light d-flex justify-content-center align-items-center py-5"
            style={{
                minHeight: "100vh",
                justifyContent: "center",
                background: "linear-gradient(to right, #bdc3c7, #2c3e50)",
            }}
        >
            <div>
                <div
                    className="bg-white border shadow"
                    style={{ width: 400, maxWidth: "100%", borderRadius: "3%" }}
                >
                    <div
                        className="border-bottom d-flex justify-content-center"
                        style={{ height: 140 }}
                    >
                        <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}/images/no-avatar.webp`}
                            alt="No avatar"
                            className="rounded-circle bg-white"
                            style={{ marginTop: 100 }}
                            width={80}
                            height={80}
                        />
                    </div>
                    <div className="p-5">
                        <form
                            className="mt-5"
                            onSubmit={handleSubmit(handleLogin)}
                        >
                            <InputField
                                control={control}
                                name="email"
                                id="email"
                                label={
                                    <>
                                        <i className="fas fa-fw fa-user"></i>{" "}
                                        <span>Tài khoản</span>
                                    </>
                                }
                                placeholder="Nhập email"
                            />
                            <InputField
                                control={control}
                                type={passwordShown ? "text" : "password"}
                                name="password"
                                id="password"
                                label={
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <i className="fas fa-fw fa-lock"></i>{" "}
                                            <span>Mật khẩu</span>
                                        </div>
                                        <div
                                            onClick={() =>
                                                setPasswordShown(!passwordShown)
                                            }
                                            role="button"
                                            className="d-flex align-items-center gap-2"
                                        >
                                            <i className="fa-solid fa-eye"></i>
                                            <span>Hiện mật khẩu</span>
                                        </div>
                                    </div>
                                }
                                placeholder="Nhập mật khẩu"
                            />

                            {/* <FormCheck
                                {...register("rememberMe")}
                                name="rememberMe"
                                label="Nhớ tài khoản"
                                className="mb-3"
                            /> */}

                            <div className="d-grid">
                                <Button type="submit">
                                    <i className="fas fa-fw fa-sign-in-alt"></i>
                                    <span>&nbsp;&nbsp;Đăng nhập</span>
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
