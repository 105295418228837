const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    theme: {
        color: "primary",
    },
    language: localStorage.getItem("language") || "vn", // vn / en
    sidebar: {
        show: true,
        menu: [
            {
                name: "home",
                href: `${process.env.REACT_APP_PUBLIC_URL}/home`,
                icon: "fas fa-fw fa-home",
                title: {
                    vn: "Trang chủ",
                    en: "Home",
                },
            },
            {
                name: "staff",
                href: `${process.env.REACT_APP_PUBLIC_URL}/staff`,
                icon: "fas fa-fw fa-user",
                title: {
                    vn: "Nhân viên",
                    en: "Staff",
                },
            },
            {
                name: "ecosystem",
                href: `${process.env.REACT_APP_PUBLIC_URL}/ecoSystem`,
                icon: "fas fa-fw fa-seedling",
                title: {
                    vn: "Hệ sinh thái",
                    en: "Ecosystem",
                },
            },
            {
                name: "medicalProduct",
                href: `${process.env.REACT_APP_PUBLIC_URL}/medical-product`,
                icon: "fas fa-fw fa-briefcase-medical",
                title: {
                    vn: "Sản phẩm y tế",
                    en: "Medical product",
                },
            },
            {
                name: "news",
                href: `${process.env.REACT_APP_PUBLIC_URL}/news`,
                icon: "fas fa-fw fa-rectangle-list",
                title: {
                    vn: "Tin tức",
                    en: "News",
                },
            },
            {
                name: "seo",
                href: `${process.env.REACT_APP_PUBLIC_URL}/seo`,
                icon: "fas fa-fw fa-square-rss",
                title: {
                    vn: "SEO",
                    en: "SEO",
                },
            },
        ],
    },
};

const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
            localStorage.setItem("language", action.payload);
            return state;
        },
        setShowSidebar(state, action) {
            state.sidebar.show = action.payload;
            return state;
        },
    },
});

// Selectors
export const selectSidebar = (state) => state.admin.sidebar;
export const selectTheme = (state) => state.admin.theme;
export const selectLanguage = (state) => state.admin.language;

// Actions
export const adminActions = adminSlice.actions;

// Reducer
const adminReducer = adminSlice.reducer;
export default adminReducer;
