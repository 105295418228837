import { SplitButton } from "components/Buttons";
import useTrans from "../../hook/useTrans"
import { Modal } from "react-bootstrap";

export const Confirm = (props) => {
    const t = useTrans();
    const {
        show,
        title,
        message,
        onClose,
        onCancel,
        onConfirm,
        textCancel = t.popup.no,
        textConfirm = t.popup.yes,
    } = props;

    const handleCancel = () => {
        onCancel?.();
        onClose();
    };

    const handleConfirm = () => {
        onConfirm?.();
        onClose();
    };

    return (
        <Modal show={show} onHide={onClose} backdrop="static" centered>
            {title && (
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold" as="h5">
                        {title}
                    </Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <SplitButton
                    size="sm"
                    variant="secondary"
                    icon="fas fa-fw fa-times"
                    text={textCancel}
                    onClick={handleCancel}
                />
                <SplitButton
                    size="sm"
                    variant="primary"
                    icon="fas fa-fw fa-check"
                    text={textConfirm}
                    onClick={handleConfirm}
                />
            </Modal.Footer>
        </Modal>
    );
};
