import newsApi from 'api/newsApi';
import { selectLanguage } from 'app/adminSlice';
import { SplitButton } from 'components/Buttons';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NewsList } from '../components';
import { Link } from 'react-router-dom';
const NewsPage = () => {
	const language = useSelector(selectLanguage);
    const [load, setLoad] = useState(false);
	// staff list
	const [newsList, setNewsList] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await newsApi.getList();

				if (res.succeeded) {
					setNewsList(language === 'vn' ? res.data.vn : res.data.en);
				}
			} catch (error) {}
		};

		fetchData();
		setLoad(false);
	}, [language,load]);

	// return
	return (
		<Card className="shadow">
			<Card.Header className="fw-bold">
				{language === 'vn' ? 'Tin tức' : 'News'}
			</Card.Header>
			<Card.Body>
				<div className="mb-3">
				<Link to={`${process.env.REACT_APP_PUBLIC_URL}/news/add`}>
					<SplitButton
							 icon="fas fa-add"
							text={language === 'vn' ? 'Thêm mới' : 'Add'}
						/>
				</Link>
				</div>

				<NewsList language={language} newsList={newsList} setLoad={setLoad}/>
			</Card.Body>
		</Card>
	);
};

export default NewsPage;
