import ecoSystemApi from "api/ecoSystemApi";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import EcoSystemForm from "../components/EcoSytemForm";
import useTrans from "../../../hook/useTrans";
const EcoSystemDetail = ({ detail }) => {
    const t = useTrans();
    const page = useParams().id;
    const [pageInfo, setPageInfo] = useState(null);

    useEffect(() => {
        if (detail === "update") {
            const getInfo = async () => {
                const data = await ecoSystemApi.getDetail(page);
                setPageInfo(data.data);
            };
            getInfo();
        }
    }, []);

    return (
        <div>
            <Card className="shadow">
                <Card.Header className="fw-bold">{t.ecoSystem.ecoSystemDetail}</Card.Header>
                <Card.Body>
                    {pageInfo && (
                        <EcoSystemForm
                            label_btn={"Update"}
                            method={"update"}
                            pageInfo={pageInfo}
                        />
                    )}
                </Card.Body>
                {/* {detail === "add" && (
                    <>
                        <Card.Header className="fw-bold">
                            Add new EcoSystem
                        </Card.Header>
                        <Card.Body>
                            <EcoSystemForm label_btn={"Add"} method={"add"} />
                        </Card.Body>
                    </>
                )} */}
            </Card>
        </div>
    );
};

export default EcoSystemDetail;
