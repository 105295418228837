import { useState } from "react";
//bootstrap
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
//component
import staffApi from "api/staffApi";
import ReactQuillComponent from "components/Common/ReactQuillComponent";
import { InputField } from "components/FormFields";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
//form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useTrans from "../../../hook/useTrans";

const StaffForm = (props) => {
    const t = useTrans();
    const { language, paramSubmit, pageInfo, label_btn, idStaff } = props;

    const navigate = useNavigate();

    const [previewImage, setPreviewImage] = useState();
    const [previewImageHover, setPreviewImageHover] = useState();

    const [showVN, setShowVN] = useState(true);
    const [showEN, setShowEN] = useState(true);

    const [baseImage, setBaseImage] = useState("");
    const [baseImageHover, setBaseImageHover] = useState("");

    const [expEditorVN, setExpEditorVN] = useState("");
    const [expEditorEN, setExpEditorEN] = useState("");

    const uploadImage = async (e, type) => {
        const file = e.target.files[0];

        if (!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
            if (type === "image") {
                setError("image", {
                    message:
                        "Chỉ chấp nhận các định dạng jpg, jpeg, png, gif, webp.",
                });
            } else {
                setError("image_hover", {
                    message:
                        "Chỉ chấp nhận các định dạng jpg, jpeg, png, gif, webp.",
                });
            }
            return;
        } else {
            const base64 = await convertBase64(file);

            if (type === "image") {
                setBaseImage(base64);
                setPreviewImage(URL.createObjectURL(file));
                clearErrors("image");
            } else {
                setBaseImageHover(base64);
                setPreviewImageHover(URL.createObjectURL(file));
                clearErrors("image_hover");
            }
        }
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const onSubmit = async (data) => {
        const {
            name,
            order,
            role_en,
            role_vn,
            position_en,
            position_vn,
            // experienced_vn,
            // experienced_en,
            // image,
            // image_hover,
        } = data;

        const data_post = {
            name: name,
            en: {
                role: role_en,
                position: position_en,
                experienced: expEditorEN,
                // experienced: experienced_en,
            },
            vn: {
                role: role_vn,
                position: position_vn,
                experienced: expEditorVN,
            },
            images: {
                image: baseImage,
                imageHover: baseImageHover,
            },
            orderNumber: order,
        };
        if (baseImage && baseImageHover) {
            const res = await staffApi.create(data_post);
            if (res.succeeded) {
                toast.success("Create staff success");
                navigate(`${process.env.REACT_APP_PUBLIC_URL}/staff`);
            }
        } else {
            toast.warning("Bạn submit nhanh quá, hãy thử lại");
        }
    };

    const schema = yup
        .object({
            name: yup
                .string()
                .required()
                .max(30, "Vượt quá số kí tự cho phép, tối đa chỉ 30 kí tự"),
            role_en: yup
                .string()
                .required()
                .max(30, "Vượt quá số kí tự cho phép, tối đa chỉ 30 kí tự"),
            role_vn: yup
                .string()
                .required()
                .max(30, "Vượt quá số kí tự cho phép, tối đa chỉ 30 kí tự"),
            position_en: yup
                .string()
                .max(150, "Vượt quá số kí tự cho phép, tối đa chỉ 150 kí tự"),
            position_vn: yup
                .string()
                .max(150, "Vượt quá số kí tự cho phép, tối đa chỉ 150 kí tự"),
            experienced_en: yup
                .string()
                .required()
                .max(300, "Vượt quá số kí tự cho phép, tối đa chỉ 300 kí tự"),
            experienced_vn: yup
                .string()
                .required()
                .max(300, "Vượt quá số kí tự cho phép, tối đa chỉ 300 kí tự"),
            order: yup
                .number()
                .required("Bạn phải nhập số")
                .moreThan(0, "Số phải lớn hơn 0")
                .typeError("Bạn phải nhập số"),
            // image: yup.string().required(),
            // image_hover: yup.string().required(),
        })
        .required();
    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            order: "",
            role_en: "",
            role_vn: "",
            position_en: "",
            position_vn: "",
            experienced_en: "..",
            experienced_vn: "..",
            // name: "phong ha",
            // order: "",
            // role_en: "developer",
            // role_vn: "role vn",
            // position_en: "position EN",
            // position_vn: "position VN",
            // experienced_en: "1 year",
            // experienced_vn: "experienced vn",
        },
        resolver: yupResolver(schema),
    });

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} className="">
                <InputField
                    className="w-50"
                    control={control}
                    name="name"
                    label={<>{t.staff.name}</>}
                    readOnly={pageInfo ? true : false}
                    placeholder={t.staff.name}
                />
                <InputField
                    className="w-50"
                    control={control}
                    name="order"
                    label={<>{t.staff.orderNumber}</>}
                    placeholder={t.staff.orderNumber}
                />
                <Row>
                    <Col>
                        <Button
                            variant="info"
                            className="mb-2 d-block"
                            onClick={() => setShowVN(!showVN)}
                        >
                            Vietnam
                        </Button>{" "}
                        {showVN && (
                            <>
                                <InputField
                                    control={control}
                                    name="role_vn"
                                    label={<>{t.staff.role}</>}
                                    placeholder={t.staff.role}
                                />
                                <InputField
                                    control={control}
                                    name="position_vn"
                                    label={<>{t.staff.position}</>}
                                    placeholder={t.staff.position}
                                    as="textarea"
                                    style={{ height: "100px" }}
                                />{" "}
                                {/* <InputField
                                    control={control}
                                    name="experienced_vn"
                                    label={<>Experienced VN</>}
                                    placeholder="experienced"
                                    as="textarea"
                                    style={{ height: "70px" }}
                                /> */}
                                <ReactQuillComponent
                                    label={<>{t.staff.experienced}</>}
                                    setDataEditor={setExpEditorVN}
                                    dataEditor={expEditorVN}
                                    name="experienced_vn"
                                    // defaultValue="experienced vn"
                                />
                            </>
                        )}
                    </Col>
                    <Col>
                        <Button
                            variant="info"
                            className="mb-2 d-block"
                            onClick={() => setShowEN(!showEN)}
                        >
                            English
                        </Button>{" "}
                        {showEN && (
                            <>
                                <InputField
                                    control={control}
                                    name="role_en"
                                    label={<>{t.staff.role}</>}
                                    placeholder={t.staff.role}
                                />
                                <InputField
                                    control={control}
                                    name="position_en"
                                    label={<>{t.staff.position}</>}
                                    placeholder={t.staff.position}
                                    as="textarea"
                                    style={{ height: "100px" }}
                                />
                                <ReactQuillComponent
                                    label={<>{t.staff.experienced}</>}
                                    setDataEditor={setExpEditorEN}
                                    dataEditor={expEditorEN}
                                    name="experienced_en"
                                    // defaultValue="experienced en"
                                />
                            </>
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col className="d-flex flex-column">
                        <InputField
                            control={control}
                            name="image"
                            label={<>{t.staff.image}</>}
                            placeholder="image"
                            type="file"
                            onChange={(e) => uploadImage(e, "image")}
                            required
                        />
                        {previewImage && (
                            <img
                                className="image_preview"
                                src={previewImage}
                                alt="preview-image"
                            />
                        )}
                    </Col>
                    <Col className="d-flex flex-column">
                        <InputField
                            control={control}
                            name="image_hover"
                            label={<>{t.staff.imageHover}</>}
                            placeholder="image hover"
                            type="file"
                            onChange={(e) => uploadImage(e, "image_hover")}
                            required
                        />
                        {previewImageHover && (
                            <img
                                className="image_preview"
                                src={previewImageHover}
                                alt="preview-image-hover"
                            />
                        )}
                    </Col>
                </Row>

                <Button type="submit" className="mt-3">
                    <i className="fa-solid fa-check"></i>
                    &nbsp;&nbsp;{t.button.add}
                </Button>

                {/* {baseImage && baseImageHover && (
                    <Button type="submit" className="mt-3">
                        <i className="fa-solid fa-check"></i>
                        &nbsp;&nbsp;{label_btn}
                    </Button>
                )} */}
            </Form>
        </>
    );
};

export default StaffForm;
