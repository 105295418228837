import { CircleButton } from "components/Buttons";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import newsApi from "api/newsApi";
import { Confirm } from "components/Common/Confirm";
import useTrans from "../../../hook/useTrans";

export const NewsList = (props) => {
    const t = useTrans();
    const navigate = useNavigate();
    const { language, newsList, setLoad } = props;

    const [show, setShow] = useState(false);
    const [deleteId, setdeleteId] = useState("");
    const [newDeleteName, setNewDeleteName] = useState("");

    const handleShow = (id, title) => {
        setShow(true);
        setdeleteId(id);
        setNewDeleteName(title);
    };

    const handleClose = () => {
        setShow(false);
        setdeleteId("");
    };

    const handleDelete = async () => {
        setShow(false);
        const param = { id: deleteId, language: "ev" };
        const res = await newsApi.delete(param);
        if (res.succeeded === true) {
            toast.success("Remove new successfully");
            setLoad(true);
        }
    };

    const header = [
        {
            name: "title",
            title: {
                vn: "Tên tin tức",
                en: "Title",
            },
        },
        {
            name: "date",
            title: {
                vn: "Ngày tạo",
                en: "Created date",
            },
        },
    ];

    return newsList.length > 0 ? (
        <div className="table-reponsive">
            <Table className="mb-0 table-hover">
                <thead className="table-primary thead-sticky">
                    <tr align="center" className="align-middle">
                        {header.map((item) => (
                            <th key={item.name}>
                                {language === "vn"
                                    ? item.title.vn
                                    : item.title.en}
                            </th>
                        ))}
                        <th>{language === "vn" ? "Công cụ" : "Controls"}</th>
                    </tr>
                </thead>
                <tbody>
                    {newsList.map((item) => (
                        <tr key={item.id.toString()}>
                            <td>
                                <div className="item">
                                    <img
                                        src={item.image}
                                        alt="News"
                                        width={100}
                                    />
                                    {item.title}
                                </div>
                            </td>
                            <td align="center">{item.date}</td>

                            <td>
                                <div className="controls">
                                    {/* <CircleButton
									icon="fas fa-eye"
									title={language === 'vn' ? 'Xem chi tiết' : 'Edit'}
									size="sm"
									className="mx-1"
								/> */}
                                    <CircleButton
                                        icon="fas fa-edit"
                                        title={
                                            language === "vn"
                                                ? "Cập nhật"
                                                : "Edit"
                                        }
                                        size="sm"
                                        className="mx-1"
                                        onClick={() =>
                                            navigate(
                                                `${process.env.REACT_APP_PUBLIC_URL}/news/update/` +
                                                    item.id
                                            )
                                        }
                                    />
                                    <CircleButton
                                        variant="danger"
                                        icon="fas fa-trash"
                                        title={
                                            language === "vn" ? "Xóa" : "Delete"
                                        }
                                        size="sm"
                                        className="mx-1"
                                        onClick={() =>
                                            handleShow(item.id, item.title)
                                        }
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Confirm
                show={show}
                message={`${t.popupDelete.title} " ${newDeleteName} " ?`}
                onConfirm={handleDelete}
                onClose={handleClose}
            />
        </div>
    ) : (
        <div className="alert alert-danger mb-0">Không có dữ liệu</div>
    );
};
