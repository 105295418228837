import aboutUsApi from "api/aboutUsApi";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import StaffForm from "../componets/StaffForm";
import StaffFormUpdate from "../componets/StaffFormUpdate";
import useTrans from '../../../hook/useTrans'
const StaffDetail = ({ detail }) => {
    const idStaff = useParams().id;
    const t = useTrans()
    const [pageInfo, setPageInfo] = useState(null);

    useEffect(() => {
        if (detail === "update") {
            const getInfo = async () => {
                const data = await aboutUsApi.getDetailStaff(idStaff);
                setPageInfo(data.data);
            };
            getInfo();
        }
    }, []);

    return (
        <div>
            <Card className="shadow">
                {detail === "add" && (
                    <>
                        <Card.Header className="fw-bold">
                           {t.staff.staffAdd}
                        </Card.Header>
                        <Card.Body>
                            <StaffForm label_btn={"Add"} />
                        </Card.Body>
                    </>
                )}
                {detail === "update" && (
                    <>
                        <Card.Header className="fw-bold">
                           {t.staff.staffDetail}
                        </Card.Header>
                        <Card.Body>
                            {pageInfo && (
                                <StaffFormUpdate
                                    label_btn={"Update"}
                                    pageInfo={pageInfo}
                                    idStaff={idStaff}
                                />
                            )}
                        </Card.Body>
                    </>
                )}
            </Card>
        </div>
    );
};

export default StaffDetail;
