import { adminActions, selectSidebar, selectTheme } from "app/adminSlice";
import { selectCurrentUser } from "app/authSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Footer, Header, Sidebar } from "../Common";

export const AdminLayout = (props) => {
    const { element } = props;

    const dispatch = useDispatch();
    const sidebar = useSelector(selectSidebar);
    const theme = useSelector(selectTheme);
    const currentUser = useSelector(selectCurrentUser);
    const navigate = useNavigate();

    const handleShowSidebar = () => {
        dispatch(adminActions.setShowSidebar(!sidebar.show));
    };

    useEffect(() => {
        if (!currentUser) {
            navigate(`${process.env.REACT_APP_PUBLIC_URL}/login`);
        }
    });

    return (
        <div id="wrapper">
            <Sidebar
                sidebar={sidebar}
                theme={theme}
                hanldeShow={handleShowSidebar}
            />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Header handleShowSidebar={handleShowSidebar} />
                    <div className="container-fluid">{element}</div>
                </div>
                <Footer />
            </div>
        </div>
    );
};
