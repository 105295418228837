import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import NewUpdate from "../components/NewUpdate";
import { useParams } from "react-router-dom";
import newsApi from "api/newsApi";
import NewsAdd from "../components/NewForm";
import useTrans from "../../../hook/useTrans";

const NewDetailPage = ({ detail }) => {
    const t = useTrans();
    const id = useParams().id;
    const [pageInfo, setPageInfo] = useState(null);
    useEffect(() => {
        if (detail === "update") {
            const getInfo = async () => {
                const data = await newsApi.getDetail(id);
                setPageInfo(data.data);
            };
            getInfo();
        }
    }, []);
    return (
        <Card className="shadow">
            {detail === "add" && (
                <>
                    <Card.Header className="fw-bold">{t.news.newsAdd}</Card.Header>
                    <Card.Body>
                        <NewsAdd
                            label_btn={"Add"}
                            method={"add"}
                            pageInfo={pageInfo}
                        />
                    </Card.Body>
                </>
            )}
            {detail === "update" && (
                <>
                    <Card.Header className="fw-bold">{t.news.newUpdate}</Card.Header>
                    <Card.Body>
                        {pageInfo && (
                            <NewUpdate
                                label_btn={"Update"}
                                method={"update"}
                                pageInfo={pageInfo}
                            />
                        )}
                    </Card.Body>
                </>
            )}
        </Card>
    );
};
export default NewDetailPage;
