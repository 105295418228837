import { NotFound } from "components/Common/NotFound";
import LoginPage from "features/Auth/pages/LoginPage";
import EcoSystemDetail from "features/EcoSystem/pages/EcoSystemDetail";
import EcoSystemPage from "features/EcoSystem/pages/EcoSystemPage";
import MedicalProductPage from "features/MedicalProduct/pages/MedicalProductPage";
import NewsPage from "features/News/pages/NewsPage";
import SeoDetailPage from "features/SEO/pages/SeoDetailPage";
import SEOPage from "features/SEO/pages/SEOPage";
import StaffDetail from "features/Staff/pages/StaffDetail";
import StaffPage from "features/Staff/pages/StaffPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AdminLayout } from "./components/Layout/Admin";
import HomePage from "./features/Home/pages/HomePage";
import NewDetailPage from "features/News/pages/NewDetailPage";
import MedicalDetailPage from "features/MedicalProduct/pages/MedicalDetailPage";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/login`}
                        exact
                        element={<LoginPage isLogin={true} />}
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/logout`}
                        exact
                        element={<LoginPage isLogin={false} />}
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/home`}
                        element={<AdminLayout element={<HomePage />} />}
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/staff`}
                        element={<AdminLayout element={<StaffPage />} />}
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/staff/add`}
                        element={
                            <AdminLayout
                                element={<StaffDetail detail="add" />}
                            />
                        }
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/staff/update/:id`}
                        element={
                            <AdminLayout
                                element={<StaffDetail detail="update" />}
                            />
                        }
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/ecosystem`}
                        element={<AdminLayout element={<EcoSystemPage />} />}
                    />{" "}
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/medical-product`}
                        element={
                            <AdminLayout element={<MedicalProductPage />} />
                        }
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/medical-product/add`}
                        element={
                            <AdminLayout
                                element={<MedicalDetailPage detail="add" />}
                            />
                        }
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/medical-product/update/:id`}
                        element={
                            <AdminLayout
                                element={<MedicalDetailPage detail="update" />}
                            />
                        }
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/news`}
                        element={<AdminLayout element={<NewsPage />} />}
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/news/add`}
                        element={
                            <AdminLayout
                                element={<NewDetailPage detail="add" />}
                            />
                        }
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/news/update/:id`}
                        element={
                            <AdminLayout
                                element={<NewDetailPage detail="update" />}
                            />
                        }
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/seo`}
                        element={<AdminLayout element={<SEOPage />} />}
                    />
                    {/* <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/seo/add`}
                        element={<AdminLayout element={<SEOAdd />} />}
                    /> */}
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/seo/add`}
                        element={
                            <AdminLayout
                                element={<SeoDetailPage detail="add" />}
                            />
                        }
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/seo/update/:page`}
                        element={
                            <AdminLayout
                                element={<SeoDetailPage detail="update" />}
                            />
                        }
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/ecoSystem/:id`}
                        element={
                            <AdminLayout
                                element={<EcoSystemDetail detail="update" />}
                            />
                        }
                    />
                    <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}`}
                        element={<LoginPage isLogin={true} />}
                    />
                    {/* <Route
                        path={`${process.env.REACT_APP_PUBLIC_URL}/ecoSystem/add`}
                        element={
                            <AdminLayout
                                element={<EcoSystemDetail detail="add" />}
                            />
                        }
                    /> */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
