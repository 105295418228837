import ecoSystemApi from "api/ecoSystemApi";
import { CircleButton } from "components/Buttons";
import { Confirm } from "components/Common/Confirm";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const EcoSytemList = (props) => {
    const { language, ecoSytemList } = props;
    const navigate = useNavigate();

    const header = [
        {
            name: "title",
            title: {
                vn: "Tên",
                en: "Title",
            },
        },
        {
            name: "description",
            title: {
                vn: "Mô tả",
                en: "Description",
            },
        },
    ];

    const [show, setShow] = useState(false);
    const [pageDelete, setPageDelete] = useState("");
    const [pageDeleteName, setPageDeleteName] = useState("");

    const handleShow = (id, title) => {
        setShow(true);
        setPageDelete(id);
        setPageDeleteName(title);
    };
    const handleClose = () => {
        setShow(false);
        setPageDelete("");
    };

    const handleDelete = async () => {
        setShow(false);
        const param = { id: pageDelete, language: "ev" };
        const res = await ecoSystemApi.delete(param);

        if (res.succeeded === true) {
            toast.success("Remove item successfully");
            // setLoad(true);
        }
    };

    return ecoSytemList.length > 0 ? (
        <div className="table-reponsive">
            <Table className="mb-0 table-hover">
                <thead className="table-primary thead-sticky">
                    <tr align="center">
                        {header.map((item) => (
                            <th key={item.name} className="text-start">
                                {language === "vn"
                                    ? item.title.vn
                                    : item.title.en}
                            </th>
                        ))}
                        <th>{language === "vn" ? "Công cụ" : "Controls"}</th>
                    </tr>
                </thead>
                <tbody>
                    {ecoSytemList.map((item) => (
                        <tr key={item.id.toString()}>
                            <td className="w-25">{item.title}</td>
                            <td className="w-50">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.description,
                                    }}
                                />
                            </td>

                            <td className="w-25">
                                <div className="controls">
                                    {/* <CircleButton
                                    icon="fas fa-eye"
                                    title={
                                        language === "vn"
                                            ? "Xem chi tiết"
                                            : "Detail"
                                    }
                                    size="md"
                                    className="mx-1"
                                /> */}
                                    <CircleButton
                                        icon="fas fa-edit"
                                        title={
                                            language === "vn"
                                                ? "Cập nhật"
                                                : "Edit"
                                        }
                                        size="md"
                                        className="mx-1"
                                        onClick={() =>
                                            navigate(
                                                `${process.env.REACT_APP_PUBLIC_URL}/ecoSystem/` +
                                                    item.id
                                            )
                                        }
                                    />
                                    {/* <CircleButton
                                    variant="danger"
                                    icon="fas fa-trash"
                                    title={language === "vn" ? "Xóa" : "Delete"}
                                    size="md"
                                    className="mx-1"
                                    onClick={() => handleShow(item.id, item.title)}
                                /> */}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Confirm
                show={show}
                message={`bạn có muốn xóa item ${pageDeleteName} không ?`}
                onConfirm={handleDelete}
                onClose={handleClose}
                textCancel="Không"
            />
        </div>
    ) : (
        <div className="alert alert-danger mb-0">Không có dữ liệu</div>
    );
};
