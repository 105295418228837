import axiosClient from "./axiosClient";
const apiUrl = "/TMAStaff";

const staffApi = {
    // POST
    create: (params) => {
        const url = `${apiUrl}/create`;
        return axiosClient.post(url, params);
    },
    update: (params) => {
        const url = `${apiUrl}/update`;
        return axiosClient.post(url, params);
    },

    // DELETE
    delete: (params) => {
        const url = `${apiUrl}/delete`;
        return axiosClient.put(url, params);
    },
};

export default staffApi;
