import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useController } from "react-hook-form";
import { Form } from "react-bootstrap";

const ReactQuillComponent = ({
    setDataEditor,
    dataEditor,
    name,
    label,
    defaultValue,
    modules_new,
    formats_new,
}) => {
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link"],
        ],
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
    ];
    const modules_new_image = {
        toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image"],
        ],
    };

    const formats_new_image = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
    ];

    return (
        <Form.Group className="mb-3">
            {label && <Form.Label className="w-100">{label}</Form.Label>}
            <ReactQuill
                theme="snow"
                onChange={setDataEditor}
                name={name}
                modules={modules_new ? modules_new_image : modules}
                formats={formats_new ? formats_new_image : formats}
                defaultValue={defaultValue}
            />
        </Form.Group>
    );
};

export default ReactQuillComponent;
