import { configureStore } from '@reduxjs/toolkit';
import adminReducer from './adminSlice';
import authReducer from './authSlice';

const rootReducer = {
	admin: adminReducer,
	auth: authReducer
};

const store = configureStore({
	reducer: rootReducer
});

export default store;
