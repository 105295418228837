import seoApi from "api/seoApi";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import SEOForm from "../components/SEOForm";
import useTrans from '../../../hook/useTrans'

const SeoDetailPage = ({ detail }) => {
    const page = useParams().page;
    const t = useTrans()
    const [pageInfo, setPageInfo] = useState(null);

    useEffect(() => {
        if (detail === "update") {
            const getInfo = async () => {
                const data = await seoApi.getSEOInfo(page);
                setPageInfo(data.data);
            };
            getInfo();
        }
    }, []);

    return (
        <Card className="shadow">
            {detail === "add" && (
                <>
                    <Card.Header className="fw-bold">{t.seo.addSeo}</Card.Header>
                    <Card.Body>
                        <SEOForm label_btn={"Add"} method={"add"} />
                    </Card.Body>
                </>
            )}
            {detail === "update" && (
                <>
                    <Card.Header className="fw-bold">{t.seo.updateSeo}</Card.Header>
                    <Card.Body>
                        {pageInfo && (
                            <SEOForm
                                label_btn={"Update"}
                                method={"update"}
                                pageInfo={pageInfo}
                            />
                        )}
                    </Card.Body>
                </>
            )}
        </Card>
    );
};

export default SeoDetailPage;
