import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { InputField } from "components/FormFields";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import medicalProductApi from "api/medicalProductApi";

import { Confirm } from "components/Common/Confirm";
import ReactQuillComponent from "components/Common/ReactQuillComponent";
import useTrans from "../../../hook/useTrans";
import { SketchPicker } from "react-color";
const MedicalForm = (props) => {
    const t = useTrans();
    const {pageInfo } = props;
    const navigate = useNavigate();
    const [showVN, setShowVN] = useState(true);
    const [showEN, setShowEN] = useState(true);
    const [imageBase64, setImageBase64] = useState();
    const [imagePopupBase64, setImagePopupBase64] = useState();
    const onSubmit = async (data) => {
        const {
            title_en,
            title_vn,
            smallDescription_vn,
            smallDescription_en,
            description_en,
            description_vn,
            subTitle_vn,
            subTitle_en,
        } = data;
        const data_post = {
            colorBackground: bgColorHex,
            images: {
                image: imageBase64,
                imagePopUp: imagePopupBase64,
            },
            en: {
                title: title_en,
                subTitle: subTitle_en,
                description: description_en,
                smallDescription: smallDescription_en,
            },
            vn: {
                title: title_vn,
                subTitle: subTitle_vn,
                description: description_vn,
                smallDescription: smallDescription_vn,
            },
        };

        const res = await medicalProductApi.create(data_post);
        if (res.succeeded) {
            toast.success("Add MedicalProduct success");
            navigate(`${process.env.REACT_APP_PUBLIC_URL}/medical-product`);
        }
    };

    const schema = yup
        .object({
            title_en: yup.string().required(),
            title_vn: yup.string().required(),
            smallDescription_en: yup.string().required(),
            smallDescription_vn: yup.string().required(),
            description_en: yup.string().required(),
            description_vn: yup.string().required(),
            subTitle_en: yup.string().required(),
            subTitle_vn: yup.string().required(),
        })
        .required();
    const {
        control,
        handleSubmit,
        setValue,
        register,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: pageInfo
            ? {
                  title_vn: pageInfo.vn.title,
                  title_en: pageInfo.en.title,
                  smallDescription_en: pageInfo.en.smallDescription,
                  smallDescription_vn: pageInfo.vn.smallDescription,
                  description_en: pageInfo.en.description,
                  description_vn: pageInfo.vn.description,
                  subTitle_vn: pageInfo.vn.subTitle,
                  subTitle_en: pageInfo.en.subTitle,
              }
            : {
                  title_vn: "",
                  title_en: "",
                  smallDescription_vn: "",
                  smallDescription_en: "",
                  description_vn: "",
                  description_en: "",
                  subTitle_vn: "",
                  subTitle_en: "",
              },
        resolver: yupResolver(schema),
    });

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    //image
    const uploadImageCover = async (e,type) => {
        const file = e.target.files[0];

        if(!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)){
            if (type === "image") {
                setError("image", {
                    message:
                        "Chỉ chấp nhận các định dạng jpg, jpeg, png, gif, webp.",
                });
            } else {
                setError("imagePopup", {
                    message:
                        "Chỉ chấp nhận các định dạng jpg, jpeg, png, gif, webp.",
                });
            }
            return;
        }else{
            const base64 = await convertBase64(file);
            if(type === 'image'){
                setImageBase64(base64);
                clearErrors("image");
            }else{
                setImagePopupBase64(base64);
                clearErrors("imagePopup");
            }
        }
    };

    //popup cancel
    const [show, setShow] = useState(false);
    const message = t.popup.message;
    const onClose = () => {
        setShow(false);
    };
    const onConfirm = () => {
        navigate(-1);
    };
    const hanldeCancel = () => {
        setShow(true);
    };
    //validate Quill
    useEffect(() => {
        register("smallDescription_vn", { required: true, minLength: 11 });
        register("smallDescription_en", { required: true, minLength: 11 });
        register("description_vn", { required: true, minLength: 11 });
        register("description_en", { required: true, minLength: 11 });
    }, [register]);
    //change bgColor
    const [bgColorHex, setBgColorHex] = useState('#589dee');
    const handleChangeBgColor = (color) => {
        setBgColorHex(color.hex);
    };
    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)} className="row">
                <label>{t.medicalProduct.bgColor}</label>
                <div className="row m-1"  >             
                    <SketchPicker
                        color={bgColorHex}
                        onChange={handleChangeBgColor}
                    />
                    <div 
                        style={{
                            backgroundColor: bgColorHex,
                            height: "60px",
                            width:'60px',
                            transition: "ease all 500ms",
                            borderRadius:'50%',
                            margin:'20px'
                        }}
                    ></div>
                </div>
                
                <div className="col-6">
                    <InputField
                        label={t.medicalProduct.image}
                        control={control}
                        type="file"
                        name="image"
                        onChange={(e  ) => {
                            uploadImageCover(e ,'image');
                        }}
                    />
                    <br />
                    <img
                        src={imageBase64}
                        style={{ margin: "10px" }}
                        width="150px"
                    />
                </div>
                <div className="col-6">
                    <InputField
                        label={t.medicalProduct.image}
                        control={control}
                        type="file"
                        name="imagePopup"
                        onChange={(e) => {
                            uploadImageCover(e ,'imagePopup');
                        }}
                    />
                    <br />
                    <img
                        src={imagePopupBase64}
                        style={{ margin: "10px" }}
                        width="150px"
                    />
                </div>
                <hr style={{ margin: "10px", width: "98%" }} />
                <div className="col-6">
                    <Button
                        variant="secondary"
                        className="mb-2 d-block"
                        onClick={() => setShowVN(!showVN)}
                    >
                        Vietnam
                    </Button>{" "}
                    {showVN && (
                        <>
                            <InputField
                                control={control}
                                label={t.medicalProduct.title}
                                name="title_vn"
                                placeholder={t.medicalProduct.title}
                                as="textarea"
                            />
                            <InputField
                                control={control}
                                name="subTitle_vn"
                                placeholder={t.medicalProduct.subTitle}
                                label={t.medicalProduct.subTitle}
                                as="textarea"
                            />
                            <ReactQuillComponent
                                label={t.medicalProduct.smallDescription}
                                setDataEditor={(e) =>
                                    setValue("smallDescription_vn", e)
                                }
                                name="smallDescription_vn"
                            />
                            <p style={{ color: "red" }}>
                                {errors.smallDescription_vn &&
                                    "SmallDescription is a required field"}
                            </p>
                            <ReactQuillComponent
                                label={t.medicalProduct.description}
                                setDataEditor={(e) =>
                                    setValue("description_vn", e)
                                }
                                name="description_vn"
                            />
                            <p style={{ color: "red" }}>
                                {errors.description_vn &&
                                    "Description is a required field"}
                            </p>
                        </>
                    )}
                </div>
                <div className="col-6">
                    <Button
                        variant="secondary"
                        className="mb-2 d-block"
                        onClick={() => setShowEN(!showEN)}
                    >
                        English
                    </Button>
                    {showEN && (
                        <>
                            <InputField
                                control={control}
                                name="title_en"
                                placeholder={t.medicalProduct.title}
                                label={t.medicalProduct.title}
                                as="textarea"
                            />
                            <InputField
                                control={control}
                                name="subTitle_en"
                                placeholder={t.medicalProduct.subTitle}
                                label={t.medicalProduct.subTitle}
                                as="textarea"
                            />
                            <ReactQuillComponent
                                label={t.medicalProduct.smallDescription}
                                setDataEditor={(e) =>
                                    setValue("smallDescription_en", e)
                                }
                                name="smallDescription_en"
                            />
                            <p style={{ color: "red" }}>
                                {errors.smallDescription_en &&
                                    "SmallDescription is a required field"}
                            </p>
                            <ReactQuillComponent
                                label={t.medicalProduct.description}
                                setDataEditor={(e) =>
                                    setValue("description_en", e)
                                }
                            />
                            <p style={{ color: "red" }}>
                                {errors.description_en &&
                                    "Description is a required field"}
                            </p>
                        </>
                    )}
                </div>
                {imageBase64 && imagePopupBase64 && (
                    <Button
                        className="col-2"
                        style={{ margin: "10px" }}
                        type="submit"
                    >
                        <i className="fa-solid fa-check"></i>
                        &nbsp;&nbsp; {t.button.add}
                    </Button>
                )}

                <Button
                    className="btn btn-secondary col-2"
                    style={{ margin: "10px" }}
                    onClick={hanldeCancel}
                >
                    <i className="fa-solid fa-close"></i>
                    &nbsp;&nbsp;{t.button.cancel}
                </Button>
                <Confirm
                    show={show}
                    onClose={onClose}
                    onConfirm={onConfirm}
                    message={message}
                />
            </Form>
        </div>
    );
};
export default MedicalForm;
