import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

import { useParams } from "react-router-dom";
import aboutUsApi from "api/aboutUsApi";
import MedicalProductUpdate from "../components/MedicalProductUpdate";
import MedicalForm from "../components/MedicalForm";
import useTrans from "../../../hook/useTrans";
const MedicalDetailPage = ({ detail }) => {
    const t = useTrans();
    const id = useParams().id;
    const [pageInfo, setPageInfo] = useState(null);

    useEffect(() => {
        if (detail === "update") {
            const getInfo = async () => {
                const data = await aboutUsApi.getDetailProduct(id);
                setPageInfo(data.data);
            };
            getInfo();
        }
    }, []);

    return (
        <Card className="shadow">
            {detail === "add" && (
                <>
                    <Card.Header className="fw-bold">
                        {t.medicalProduct.medicalProductAdd}
                    </Card.Header>
                    <Card.Body>
                            <MedicalForm
                                label_btn={"Add"}
                                method={"add"}
                                pageInfo={pageInfo}
                            />
                    </Card.Body>
                </>
            )}
            {detail === "update" && (
                <>
                    <Card.Header className="fw-bold">
                    {t.medicalProduct.MedicalProductUpdate}
                    </Card.Header>
                    <Card.Body>
                        {pageInfo && (
                            <MedicalProductUpdate
                                label_btn={"Update"}
                                method={"update"}
                                pageInfo={pageInfo}
                            />
                        )}
                    </Card.Body>
                </>
            )}
        </Card>
    );
};
export default MedicalDetailPage;
