import ecoSytemApi from "api/ecoSystemApi";
import { selectLanguage } from "app/adminSlice";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { EcoSytemList } from "../components";

const EcoSystemPage = () => {
    const language = useSelector(selectLanguage);

    const [ecoSytemList, setEcoSytemList] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await ecoSytemApi.getList();

                if (res.succeeded) {
                    setEcoSytemList(
                        language === "vn" ? res.data.vn : res.data.en
                    );
                }
            } catch (error) {}
        };

        fetchData();
    }, [language]);

    return (
        <Card className="shadow">
            <Card.Header className="fw-bold">
                {language === "vn" ? "Hệ sinh thái" : "EcoSystem"}
            </Card.Header>
            <Card.Body>
                {/* <div className="mb-3">
                    <SplitButton
                        icon="fas fa-add"
                        text={language === "vn" ? "Thêm mới" : "Add"}
                        onClick={() => navigate("/admin/ecoSystem/add")}
                    />
                </div> */}

                <EcoSytemList language={language} ecoSytemList={ecoSytemList} />
            </Card.Body>
        </Card>
    );
};

export default EcoSystemPage;
