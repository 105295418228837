import aboutUsApi from "api/aboutUsApi";
import { selectLanguage } from "app/adminSlice";
import { SplitButton } from "components/Buttons";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { StaffList } from "../componets";

const StaffPage = () => {
    const language = useSelector(selectLanguage);

    const [staffList, setStaffList] = useState([]);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await aboutUsApi.getListTeam();

                if (res.succeeded) {
                    setStaffList(language === "vn" ? res.data.vn : res.data.en);
                }
            } catch (error) {}
        };

        fetchData();
    }, [language, load]);

    // return
    return (
        <Card className="shadow">
            <Card.Header className="fw-bold">
                {language === "vn" ? "Nhân viên" : "Staff"}
            </Card.Header>
            <Card.Body>
                <div className="mb-3">
                    <Link to={`${process.env.REACT_APP_PUBLIC_URL}/staff/add`}>
                        <SplitButton
                            icon="fas fa-add"
                            text={language === "vn" ? "Thêm mới" : "Add"}
                        />
                    </Link>
                </div>

                <StaffList
                    language={language}
                    staffList={staffList}
                    setLoad={setLoad}
                />
            </Card.Body>
        </Card>
    );
};

export default StaffPage;
