import axios from "axios";
import { compareAsc } from "date-fns";
import jwt_decode from "jwt-decode";
import queryString from "query-string";
import { toast } from "react-toastify";

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "content-type": "application/json",
    },
    paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
    // const token = JSON.parse(sessionStorage.getItem("accessToken"));
    const token = await GetToken();

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axiosClient.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            return response.data;
        }
        return response;
    },
    (error) => {
        if (error && error.response && error.response.data) {
            return error.response.data;
        }
        return error;
    }
);
export const GetToken = () => {
    let dateNow = new Date();

    const token = JSON.parse(sessionStorage.getItem("accessToken"));
    if (token) {
        const decoded = jwt_decode(token);
        const timeEx = decoded.exp;

        // const compare1 = format(
        //     new Date(timeEx * 1000),
        //     "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        // );
        // const compare2 = format(
        //     new Date(dateNow),
        //     "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        // );

        const result = compareAsc(timeEx * 1000, dateNow.getTime());

        //timeEx * 1000 > dateNow.getTime() => result=1
        if (result === -1 || result === 0) {
            sessionStorage.removeItem("accessToken");
            sessionStorage.removeItem("currentUser");

            // dispatch(authActions.setCurrentUser(null));
            toast.warning("Hết thời gian sử dụng, vui lòng đăng nhập lại");
            setTimeout(() => {
                window.location = `${process.env.REACT_APP_PUBLIC_URL}/login`;
            }, 2100);
        }
    }

    return token;
};

export default axiosClient;
